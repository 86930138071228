import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="app-content content m-auto">
      <div className="content-overlay"></div>
      <div className="content-wrapper">
        <div className="content-header row mt-2">
          <div className="content-header-left col-md-6 col-12 mb-2">
            <h3 className="content-header-title mb-0">Privacy Policy</h3>
          </div>
        </div>
        <div className="content-body">
          <section className="card">
            <div className="card-header">
              <h4 className="card-title">Privacy Policy</h4>
            </div>
            <div className="card-body">
              <p>Effective date: [july 2024]</p>
              <p>studiio.au ("us", "we", or "our") operates the studiio.au website (the "Service").</p>
              <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
              
              <h5>1. Information Collection And Use</h5>
              <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
              
              <h5>2. Types of Data Collected</h5>
              <h6>Personal Data</h6>
              <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
              <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Cookies and Usage Data</li>
              </ul>

              <h6>Usage Data</h6>
              <p>We may also collect information on how you interact with our Service. This Usage Data may include details such as your computer's Internet Protocol address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
