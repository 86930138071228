import React, { useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { toast } from "react-toastify";
import logoLight from "../assets/images/dropboxed-logo.png";
import { signup } from "../api/authApis";
import TermsOfServiceModal from "../components/TermsOfServiceModal";
import PrivacyPolicyModal from "../components/PrivacyPolicyModal";
import { Link } from "react-router-dom";

const SignUp = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [showTermsOfServiceModal, setShowTermsOfServiceModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [userData, setUserData] = useState({
    studioName: "",
    email: "",
    password: "",
    country: {},
    agreedToTerms: false,
  });

  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  const [validationErrors, setValidationErrors] = useState({});
  console.log(validationErrors);
  const passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
  const validationSchema = Yup.object().shape({
    studioName: Yup.string()
      .required("Studio Name is required")
      .max(63, "Studio Name must be at most 63 characters")
      .matches(/^[a-zA-Z0-9\s-]+$/, "Invalid Studio Name format"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        passwordRegex,
        "Password must be at least 6 characters and contain at least one special character (!@#$%^&*)"
      ),
    country: Yup.object().shape({
      value: Yup.string().required("Country is required"),
      label: Yup.string().required("Country is required"),
    }),
    agreedToTerms: Yup.boolean().oneOf([true], "You must agree to the terms"),
  });

  const handleChange = (name, value) => {
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(userData, { abortEarly: false });
      const subdomainValue = userData.studioName
        .toLowerCase()
        .replace(/\s/g, "");

      // Create a new object with studioName replaced
      const updatedUserData = { ...userData, studioName: subdomainValue };
      const dataToSend = {
        studioName: updatedUserData.studioName,
        email: updatedUserData.email,
        password: updatedUserData.password,
        country: updatedUserData.country.value,
      };
      const response = await signup(dataToSend);
      if (response.success) {
        toast.success(response.message);
        // Redirect to subdomain
        window.location.href = `http://${subdomainValue}.${window.location.host}/login`;
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setValidationErrors(validationErrors);
      } else {
        console.error("Signup failed:", error.message);
        toast.error("Registration failed");
      }
    }
  };

  const countryOptions = [
    { value: "USA", label: "USA" },
    { value: "UK", label: "UK" },
    { value: "Brazil", label: "Brazil" },
    { value: "Japan", label: "Japan" },
    { value: "Taiwan", label: "Taiwan" },
    { value: "Singapore", label: "Singapore" },
    { value: "Australia", label: "Australia" },
    { value: "New Zealand", label: "New Zealand" },
  ];

  console.log(userData);

  return (
    <div className="">
      <div className="content-overlay"></div>
      <div className="content-wrapper">
        <div className="content-header row"></div>
        <div className="content-body">
          <section className=" flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0 mt-1 mb-1">
                <div className="card border-grey border-lighten-3 m-0">
                  <div className="card-header border-0">
                    <div className="card-title text-center">
                      <div className="p-1 logo black-logo">
                        <a href={BASE_URL}>
                          <img src={logoLight} alt="branding logo" />
                        </a>
                      </div>
                    </div>
                    <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                      <span>Sign Up with Studiio.au</span>
                    </h6>
                  </div>

                  <div className="card-content">
                    <div className="card-body">
                      <form className="form-horizontal" onSubmit={handleSubmit}>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="text"
                            className={`form-control ${validationErrors.studioName ? "is-invalid" : ""}`}
                            id="studio-name"
                            name="studioName"
                            value={userData.studioName}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            placeholder="Studio Name"
                          />
                          <div className="form-control-position">
                            <i className="feather icon-user" />
                          </div>
                          <div className="invalid-feedback">
                            {validationErrors.studioName}
                          </div>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="email"
                            className={`form-control ${validationErrors.email ? "is-invalid" : ""}`}
                            id="user-email"
                            name="email"
                            value={userData.email}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            placeholder="Your Email Address"
                          />
                          <div className="form-control-position">
                            <i className="feather icon-mail" />
                          </div>
                          <div className="invalid-feedback">
                            {validationErrors.email}
                          </div>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            className={`form-control ${validationErrors.password ? "is-invalid" : ""}`}
                            id="user-password"
                            name="password"
                            value={userData.password}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            placeholder="Enter Password"
                          />
                          <div className="form-control-position">
                            <i className="fa fa-lock" />
                          </div>
                          <div
                            className="form-control-position"
                            style={{
                              right: "10px",
                              top: "0px",
                              position: "absolute",
                              cursor: "pointer",
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            <i
                              className={`fa ${
                                isPasswordVisible ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                          <div className="invalid-feedback">
                            {validationErrors.password}
                          </div>
                        </fieldset>
                        <fieldset className="form-group position-relative">
                          <Select
                            options={countryOptions}
                            value={selectedCountry}
                            onChange={(selectedOption) => {
                              setSelectedCountry(selectedOption);
                              setUserData((prevData) => ({
                                ...prevData,
                                country: selectedOption,
                              }));
                            }}
                            placeholder="Select your country"
                            className={`select2 ${validationErrors.country ? "is-invalid" : ""}`}
                            id="country"
                            components={{
                              Option: ({
                                data,
                                innerRef,
                                innerProps,
                              }) => (
                                <div
                                  ref={innerRef}
                                  {...innerProps}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    padding: "5px 10px",
                                  }}
                                  className="customOptionClass"
                                >
                                  <span>{data.label}</span>
                                </div>
                              ),
                            }}
                          />
                          <div className="invalid-feedback">
                            {validationErrors.country?.label}
                          </div>
                        </fieldset>
                        <fieldset className="form-group position-relative">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="agreedToTerms"
                              id="agreedToTerms"
                              checked={userData.agreedToTerms}
                              onChange={(e) =>
                                setUserData((prevData) => ({
                                  ...prevData,
                                  agreedToTerms: e.target.checked,
                                }))
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="agreedToTerms"
                            >
                              I agree to the 
                              <a
                                target="_blank"
                                href="/license-agreement"
                                className="primary text-center me-2"
                              >
                                Terms of Service 
                              </a>
                              {" and "} 
                              <a
                                target="_blank"
                                href="/privacy-policy"
                                className="primary text-center"
                              >
                                 Privacy Policy
                              </a>
                            </label>
                          </div>
                          <div className="invalid-feedback d-block">
                            {validationErrors.agreedToTerms}
                          </div>
                        </fieldset>
                        <button
                          onClick={handleSubmit}
                          className="btn btn-outline-primary btn-block"
                        >
                          Sign Up
                        </button>
                      </form>
                    </div>
                    <p className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                      <span>Already have an account?</span>
                    </p>
                    <div className="card-body">
                      <Link
                        to="/login"
                        className="btn btn-outline-danger btn-block"
                      >
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <TermsOfServiceModal
        isOpen={showTermsOfServiceModal}
        onClose={() => setShowTermsOfServiceModal(false)}
      />
      <PrivacyPolicyModal
        isOpen={showPrivacyPolicyModal}
        onClose={() => setShowPrivacyPolicyModal(false)}
      />
    </div>
  );
};

export default SignUp;
