import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { clientSignup } from "../api/authApis";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import logoLight from "../assets/images/dropboxed-logo.png";

const ClientSignup = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    business_name: "",
    password: "",
    confirm_password: "",
    subdomain: "",
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  useEffect(() => {
    const url = window.location.hostname;
    const subdomain = url.split(".")[0];
    setUserData((prevData) => ({
      ...prevData,
      subdomain: subdomain,
    }));
  }, []);

  const passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    business_name: Yup.string().required("Business Name is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        passwordRegex,
        "Password must be at least 6 characters and contain at least one special character (!@#$%^&*)"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(userData, { abortEarly: false });
      const response = await clientSignup(userData);
      if (response && response.success) {
        toast.success(response.message);
        navigate("/login");
      } else {
        toast.error(response.message);
        console.error("Registration failed:", response.message);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setValidationErrors(validationErrors);
      } else {
        console.error("Signup failed:", error.message);
      }
      //toast.error(error.message);
    }
  };

  return (
    <div className="" style={{overflowY: 'auto'}}>
      <div className="content-overlay"></div>
      <div className="content-wrapper">
        <div className="content-header row"></div>
        <div className="content-body">
          <section className=" flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0 mt-1 mb-1">
                <div className="card border-grey border-lighten-3 m-0">
                  <div className="card-header border-0">
                    <div className="card-title text-center">
                      <div className="p-1 logo black-logo">
                        <Link to={BASE_URL}>
                          <img src={logoLight} alt="branding logo" />
                        </Link>
                      </div>
                    </div>
                    <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                      <span>Sign Up with Studiio.au</span>
                    </h6>
                  </div>

                  <div className="card-content">
                    <div className="card-body">
                      <form className="form-horizontal" onSubmit={handleSubmit}>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="text"
                            className="form-control"
                            id="user-name"
                            name="name"
                            value={userData.name}
                            onChange={handleChange}
                            placeholder="Name"
                          />
                          <div className="form-control-position">
                            <i className="feather icon-user" />
                          </div>
                          <small className="text-danger text-center">
                            {validationErrors.name}
                          </small>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="email"
                            className="form-control"
                            id="user-email"
                            name="email"
                            value={userData.email}
                            onChange={handleChange}
                            placeholder="Your Email Address"
                          />
                          <div className="form-control-position">
                            <i className="feather icon-mail" />
                          </div>
                          <small className="text-danger">
                            {validationErrors.email}
                          </small>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="tel"
                            className="form-control"
                            id="user-phone"
                            name="phone"
                            value={userData.phone}
                            onChange={handleChange}
                            placeholder="Phone"
                            maxLength="10"
                          />
                          <div className="form-control-position">
                            <i className="feather icon-phone" />
                          </div>
                          <small className="text-danger">
                            {validationErrors.phone}
                          </small>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="text"
                            className="form-control"
                            id="user-business-name"
                            name="business_name"
                            value={userData.business_name}
                            onChange={handleChange}
                            placeholder="Business Name"
                          />
                          <div className="form-control-position">
                            <i className="feather icon-briefcase" />
                          </div>
                          <small className="text-danger">
                            {validationErrors.business_name}
                          </small>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            className="form-control"
                            id="user-password"
                            name="password"
                            value={userData.password}
                            onChange={handleChange}
                            placeholder="Password"
                          />
                          <div className="form-control-position">
                            <i className="feather icon-lock" />
                          </div>
                          <small className="text-danger">
                            {validationErrors.password}
                          </small>
                          <div
                            className="form-control-position"
                            style={{
                              right: "10px",
                              top: "0px",
                              position: "absolute",
                              cursor: "pointer",
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            <i
                              className={`fa ${isPasswordVisible ? "fa-eye-slash" : "fa-eye"
                                }`}
                            />
                          </div>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            className="form-control"
                            id="user-confirm-password"
                            name="confirm_password"
                            value={userData.confirm_password}
                            onChange={handleChange}
                            placeholder="Confirm Password"
                          />
                          <small className="text-danger">
                            {validationErrors.confirm_password}
                          </small>
                          <div className="form-control-position">
                            <i className="feather icon-lock" />
                          </div>
                          <div
                            className="form-control-position"
                            style={{
                              right: "10px",
                              top: "0px",
                              position: "absolute",
                              cursor: "pointer",
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            <i
                              className={`fa ${isPasswordVisible ? "fa-eye-slash" : "fa-eye"
                                }`}
                            />
                          </div>
                        </fieldset>
                        <button
                          type="submit"
                          className="btn btn-outline-primary btn-block"
                        >
                          <i className="feather icon-user" /> Register
                        </button>
                      </form>
                      <Link
                        to="/login"
                        className="btn btn-outline-danger btn-block mt-2"
                      >
                        <i className="feather icon-unlock" /> Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ClientSignup;
