import React from "react";

const EULA = () => {
  return (
    <div className="app-content content m-auto">
      <div className="content-overlay"></div>
      <div className="content-wrapper">
        <div className="content-header row mt-2">
          <div className="content-header-left col-md-6 col-12 mb-2">
            <h3 className="content-header-title mb-0">End User License Agreement (EULA)</h3>
          </div>
        </div>
        <div className="content-body">
          <section className="card">
            <div className="card-header">
              <h4 className="card-title">End User License Agreement</h4>
            </div>
            <div className="card-body">
              <p>This End User License Agreement ("Agreement") is a legal agreement between you (either an individual or a single entity) and studiio.au ("Company") for the studiio.au software product ("Software").</p>
              <h5>1. License Grant</h5>
              <p>studiio.au grants you a revocable, non-exclusive, non-transferable, limited license to download, install, and use the Software solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.</p>
              <h5>2. Restrictions</h5>
              <p>You agree not to, and you will not permit others to:</p>
              <ul>
                <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Software or make the Software available to any third party.</li>
              </ul>
              <h5>3. Modifications to Software</h5>
              <p>studiio.au reserves the right to modify, suspend, or discontinue, temporarily or permanently, the Software or any service to which it connects, with or without notice and without liability to you.</p>
              <h5>4. Term and Termination</h5>
              <p>This Agreement shall remain in effect until terminated by you or studiio.au. studiio.au may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</p>
              <h5>5. Governing Law</h5>
              <p>This Agreement shall be governed by and construed in accordance with the laws of [Your Country], without regard to its conflict of law principles.</p>
              <p>studiio.au</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EULA;
