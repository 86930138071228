import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import logoLight from "../assets/images/dropboxed-logo.png";
import { resetPassword } from "../api/authApis";

const ResetPassword = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [user, setUser] = useState({
    email: "",
    otp: "",
    password: "",
    confirm_password: "",
    matched: "",
  });

  const validationSchema = yup.object().shape({
    otp: yup.string().required('OTP is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirm_password: yup.string()
      .oneOf([yup.ref('password'), null], 'Passwords must match!')
      .required('Confirm password is required')
  });

  useEffect(() => {
    const email = new URLSearchParams(window.location.search).get("email");
    if (email) {
      setUser(prevUser => ({ ...prevUser, email }));
    } else {
      toast.error("Invalid address");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value,
      matched: name === 'confirm_password' && value !== user.password ? 'no' : ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(user, { abortEarly: false });
      const res = await resetPassword({ user });
      if (res.success) {
        toast.success(res.message);
        window.location.href = `/login`;
      } else {
        toast.error(res.message || 'Failed to reset password');
      }
    } catch (error) {
      if (error.inner) {
        error.inner.forEach(err => toast.error(err.message));
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="">
      <div className="content-overlay" />
      <div className="content-wrapper">
        <div className="content-header row"></div>
        <div className="content-body">
          <section className="flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0 mt-4 mb-4">
                <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
                  <div className="card-header border-0">
                    <div className="card-title text-center">
                      <div className="p-1 logo black-logo">
                        <a href={BASE_URL}>
                          <img src={logoLight} alt="branding logo" />
                        </a>
                      </div>
                    </div>
                    <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                      <span>Enter OTP with new password</span>
                    </h6>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="otp"
                            placeholder="OTP"
                            value={user.otp}
                            onChange={handleChange}
                            required
                          />
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="password"
                            className="form-control form-control-sm"
                            name="password"
                            placeholder="Password"
                            value={user.password}
                            onChange={handleChange}
                            required
                          />
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="password"
                            className="form-control form-control-sm"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            value={user.confirm_password}
                            onChange={handleChange}
                            required
                          />
                          <span style={{ color: "red", fontSize: "12px" }} hidden={user.matched !== "no"}>
                            The password confirmation does not match!
                          </span>
                        </fieldset>

                        <button type="submit" className="btn btn-sm btn-outline-primary btn-block">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
